<template>
  <action-dispatcher
    class="view-ebook"
    action="ebooks/get"
    :parameters="$route.params.id"
  >
    <div class="position-relative">
      <pdf-reader
        :file="file"
        :name="ebook.title"
        :progress="ebook.progress"
        :id="ebook.id"
      />
    </div>
  </action-dispatcher>
</template>

<script>
import { mapState } from "vuex";
import PdfReader from "@/components/ebook/pdf/PdfReader.vue";

export default {
  components: { PdfReader },
  computed: {
    ...mapState("ebooks", ["ebook"]),
    file() {
      return this.ebook?.pdf;
    },
  },
};
</script>
