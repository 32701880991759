<template>
  <div class="d-flex align-items-center justify-content-center">
    <button
      class="btn"
      @click="prev"
      :style="{ opacity: innerValue > 0 ? 1 : 0.4 }"
    >
      <i class="fa-regular fa-minus"></i>
    </button>
    <button class="btn p-0">{{ percentage }}%</button>
    <button
      class="btn"
      @click="next"
      :style="{ opacity: innerValue < maxZoom ? 1 : 0.4 }"
    >
      <i class="fa-regular fa-plus"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [Number, String],
      default: 1,
      validator: (value) => value <= 1,
    },
  },
  data() {
    return { innerValue: this.modelValue };
  },
  computed: {
    percentage() {
      if (typeof this.innerValue == "string") return 100;

      return Math.floor(this.innerValue * 100);
    },
    maxZoom() {
      return 2;
    },
  },
  methods: {
    prev() {
      let prev = Number(Number.parseFloat(this.innerValue - 0.1).toFixed(1));

      if (Number.isNaN(prev)) prev = 1;

      if (prev >= 0) this.innerValue = prev;
    },
    next() {
      let next = Number(Number.parseFloat(this.innerValue + 0.1).toFixed(1));

      if (Number.isNaN(next)) next = 1;

      if (next <= this.maxZoom) this.innerValue = next;
    },
  },
  watch: {
    innerValue(v) {
      this.$emit("update:modelValue", v);
    },
    modelValue(v) {
      this.innerValue = v;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn {
  color: #fff;
  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }

  &:focus {
    opacity: 0.8;
  }
}
</style>
