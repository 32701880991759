<template>
  <div id="pdf-reader" class="pdf-reader-component">
    <div
      id="content-pdf"
      :style="`
        height: ${heightControl};
        max-height: ${heightControl};
      `"
    >
      <pdf
        v-if="pdfdata"
        :src="pdfdata"
        :id="`pdf-page-${page}-id`"
        :page="page"
        v-model:scale="scale"
        @link-clicked="handle_pdf_link"
        style="width: 100%"
        class="mx-auto mb-3"
        annotation
      >
        <template v-slot:loading>
          <div class="d-flex justify-content-center px-lg-3 py-5">
            <div class="spinner-border text-primary mt-5" />
          </div>
        </template>
      </pdf>
    </div>

    <div class="topbar">
      <div class="row align-items-center">
        <div class="col-4">
          <router-link :to="{ name: 'ebooks' }" class="btn">
            <i class="fa-regular fa-arrow-left"></i> Voltar
          </router-link>
        </div>
        <div class="col-4 text-center">
          <router-link :to="{ name: 'index' }" class="btn">
            <img src="@/assets/images/logo.png" />
          </router-link>
        </div>
      </div>
    </div>

    <div v-if="!hideControls" class="controls">
      <div class="row position-relative">
        <div class="col-lg-4 order-4 order-lg-0 text-center text-lg-start">
          <button class="btn btn-pdf-name h-100">
            {{ name }}
          </button>
        </div>
        <div class="col-6 col-lg-4">
          <div class="d-flex align-items-center justify-content-center h-100">
            <button class="btn" @click="prevPage">
              <i class="fa-regular fa-arrow-left"></i>
            </button>
            <button class="btn p-0">
              {{ page }} / {{ numPages ? numPages : "∞" }}
            </button>
            <button class="btn" @click="nextPage">
              <i class="fa-regular fa-arrow-right"></i>
            </button>
          </div>
        </div>
        <div class="col-6 order-3 d-lg-none">
          <button-zoom key="mobile-zoom" class="d-lg-none" v-model="scale" />
        </div>
        <div class="col-4 col-lg-4 order-4">
          <div class="d-flex align-items-center justify-content-end">
            <button-zoom
              key="desktop-zoom"
              class="d-none d-lg-flex"
              v-model="scale"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pdfvuer from "pdfvuer";
import "pdfjs-dist/build/pdf.worker.entry"; // not needed since v1.9.1

import ButtonZoom from "./ButtonZoom.vue";
import { mapState } from "vuex";
import { get } from "lodash";

export default {
  props: {
    id: Number,
    progress: Object,
    name: String,
    file: String,
    heightControl: {
      type: String,
      default: "100vh",
    },
    widthControl: {
      type: String,
      default: "80vw",
    },
    hideControls: Boolean,
    pageControl: [Number, String],
  },
  components: {
    pdf: pdfvuer,
    ButtonZoom,
  },
  data() {
    return {
      loadedInitialPosition: false,
      page: 1,
      numPages: 0,
      pdfdata: null,
      errors: [],
      scale: "page-width",
    };
  },
  computed: {
    ...mapState("accounts", ["user"]),
    formattedZoom() {
      return Number.parseInt(this.scale * 100);
    },
  },
  beforeUnmount() {
    this.pdfdata = null;
  },
  mounted() {
    this.getPdf();

    this.disableContextMenu();
  },
  watch: {
    page(p) {
      this.saveCurrentProgress(p);
    },
    pageControl(p) {
      if (p) {
        if (p > this.page) {
          this.nextPage();
        } else if (p < this.page) {
          this.prevPage();
        }
      }
    },
  },
  methods: {
    disableContextMenu() {
      document
        .getElementById("pdf-reader")
        .addEventListener("contextmenu", (ev) => {
          ev.preventDefault();
        });
    },
    saveCurrentProgress(currentPage) {
      const permille = Math.round(
        ((currentPage - 1) / (this.numPages - 1)) * 1000
      );
      this.$store.dispatch("progress/save", {
        resource_id: this.id,
        stopped_at: permille,
        type: "ebook",
        finished: currentPage == this.numPages,
      });
    },
    nextPage() {
      if (this.page + 1 <= this.numPages) {
        this.page++;
        document.getElementById("content-pdf").scrollTop = 0;
      }
    },
    prevPage() {
      if (this.page - 1 >= 1) {
        this.page--;
        document.getElementById("content-pdf").scrollTop = 0;
      }
    },
    handle_pdf_link: function (params) {
      this.page = params.pageNumber;
    },
    getPdf() {
      this.pdfdata = pdfvuer.createLoadingTask(this.file);
      this.pdfdata.then((pdf) => {
        this.numPages = pdf.numPages;

        const savedPermille = get(this.progress, "stopped_at") || 0;
        this.page =
          Math.round((savedPermille / 1000) * (this.numPages - 1)) + 1;

        document.addEventListener("keydown", (event) => {
          if (event.code == "ArrowLeft") this.prevPage();
          if (event.code == "ArrowRight") this.nextPage();
        });
      });
    },
    download() {
      fetch(this.file, {
        method: "get",
        mode: "no-cors",
        referrerPolicy: "no-referrer",
      })
        .then((res) => res.blob())
        .then((res) => {
          var a = document.createElement("a");
          a.setAttribute("download", `${this.name}.pdf`);
          const href = URL.createObjectURL(res);
          a.href = href;
          a.setAttribute("target", "_blank");
          a.click();
          a.remove();
          URL.revokeObjectURL(href);
        });
    },
  },
};
</script>
<style src="pdfvuer/dist/pdfvuer.css"></style>
<style lang="scss" scoped>
.pdf-reader-component {
  position: relative;
  overflow-x: hidden;
  z-index: 9147483645;

  &::-webkit-scrollbar {
    width: 2px;
    height: 0;
  }

  .content {
    padding: 16px;
  }

  #content-pdf {
    position: relative;
    overflow: scroll;
    background-color: #555;

    &::-webkit-scrollbar {
      width: 2px;
      height: 0;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  ::v-deep .page {
    margin: 80px auto 70px;

    @media screen and (max-height: 991px) {
      margin-bottom: 120px;
    }
  }

  .topbar,
  .controls {
    background: rgb(45 49 66 / 70%);
    backdrop-filter: blur(2px);
    position: absolute;
    right: 0;
    left: 0;
  }

  .topbar {
    top: 0;

    img {
      height: auto;
      width: 50px;
    }
  }

  .controls {
    bottom: 0;
    padding-bottom: 10px;
    padding-top: 10px;

    .right-controls {
      position: absolute;
      top: 75%;
      left: 90%;
      transform: translate(-75%, -90%);
    }
  }

  .btn-pdf-name {
    color: #fff;
    @media screen and (max-width: 768px) {
      font-size: 12px !important;
    }
  }

  .btn {
    color: #fff;
    @media screen and (max-width: 768px) {
      font-size: 12px !important;
    }

    &:focus {
      opacity: 0.8;
    }
  }
}
</style>
